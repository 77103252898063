import React from "react"
import { useScrollAnim } from "components/hooks/hooks"
import CardProduct from "components/global/card-product/card-product"
import imgProduct from "@public/img/sample/product.jpg"
import { Button } from "components/anti"

const HomeFeatured = ({ locale, newCatalogs, wishlists }) => {
  const [trigger, anim] = useScrollAnim([0.05, 0.5, 0.75])

  return (
    <section className="sc-home-sale pt-main" ref={trigger}>
      <div className="container">
        <h2 className={`title-section mb-4 ${anim(1)}`}>
          {locale === "id" ? "Produk Terbaru" : "New Products"}
        </h2>
        <div className="home-sale-product">
          <div className="sale-product-list">
            {newCatalogs?.catalogs?.products?.map((item, i) => (
              <div key={i} className={`sale-product-item ${anim(i + 1)}`}>
                <CardProduct
                  {...item}
                  imgAlt={`sale-product-${i}`}
                  // wishlists={wishlists}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default HomeFeatured
