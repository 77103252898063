import imgCover from "@public/img/sample/img-cover.jpg"
import imgCover1 from "@public/img/sample/img-cover-1.jpg"

export const dataHomeSlider = [
  {
    title: "Nikmati Cicilan dengan bunga 0%",
    text: "3,6,12 Bulan Dengan kartu kredit BCA",
    label:
      "Installment payment is now available for credit card checkout with BCA Card",
    img: imgCover,
  },
  {
    title: "Nikmati Cicilan dengan bunga 1%",
    text: "3,6,12 Bulan Dengan kartu kredit BCA",
    label:
      "Installment payment is now available for credit card checkout with BCA Card",
    img: imgCover1,
  },
  {
    title: "Nikmati Cicilan dengan bunga 2%",
    text: "3,6,12 Bulan Dengan kartu kredit BCA",
    label:
      "Installment payment is now available for credit card checkout with BCA Card",
    img: imgCover,
  },
]
