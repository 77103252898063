import Image from "next/image"
import React from "react"
import backgroundSample from "@public/img/sample/background-1.jpg"
import { Button, Slider } from "components/anti"
import imgSample from "@public/img/sample/img-1.jpg"
import { Image as ImageAnti, Link } from "components/anti"
import { useScrollAnim } from "components/hooks/hooks"
import axios from "axios"

const instaURL = `https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,thumbnail_url,permalink&access_token=${process.env.INSTAGRAM_ACCESS_TOKEN}`

const HomeConnectUs = ({ locale }) => {
  const [trigger, anim] = useScrollAnim()

  const [instaState, setInstaState] = React.useState([])

  const handleGetInstaData = async () => {
    try {
      let { data } = await axios.get(instaURL)
      if (data) {
        setInstaState(data?.data)
      }
    } catch (error) {}
  }

  React.useEffect(() => {
    handleGetInstaData()
  }, [])

  return (
    <section className="sc-home-connect-us py-main sc-dark" ref={trigger}>
      <div className="row">
        <div className="col-md-4 d-flex px-md-5 right-col justify-content-center align-items-center">
          <div className="wrapper-title">
            <h2 className="title">
              {locale === "id" ? "Terhubung dengan Kami" : "Connect with Us"}
            </h2>
            <Button
              variant="primary"
              className="btn-fluid"
              iconLeft={"aib ai-instagram"}
              link="https://www.instagram.com/nagarey/"
            >
              {locale === "id"
                ? "Ikuti Kami Di Instagram"
                : "Follow On Instagram"}
            </Button>
          </div>
        </div>
        <div className="col-md-8 px-3 mt-4 pt-md-0 pt-2 mt-md-0">
          {instaState.length > 0 && (
            <Slider
              visibleInitial={1}
              showInitial={3.1}
              showXxlDown={3.1}
              showXlDown={3.1}
              showLgDown={3.1}
              showMdDown={2.1}
              showSmDown={2.1}
              dotsInitial={false}
              arrowsInitial={true}
              className="connect-with-us"
            >
              {instaState.map((item, i) => (
                <Link to={item.permalink} target="__blank">
                  <img
                    src={item.media_url}
                    className={`img-content ${anim(i + 1)}`}
                    alt={item.media_type}
                  />
                </Link>
              ))}
            </Slider>
          )}
        </div>
      </div>
    </section>
  )

  // return (
  //   <section className="sc-home-connect-us py-main position-relative sc-dark" ref={trigger}>
  // <div className="img-background">
  //   <Image
  //     src={backgroundSample}
  //     objectFit="cover"
  //     layout="fill"
  //     placeholder="blur"
  //   />
  // </div>
  //     <div className="container">
  //       <div className="row row-5 align-items-center">
  // <div className={`col-md-4 ${anim(1)}`}>
  // <h2 className="mb-3 title">
  //   {locale === "id" ? "Terhubung dengan Kami" : "Connect with Us"}
  // </h2>
  // <Button
  //   variant="primary"
  //   className="btn-fluid"
  //   iconLeft={"aib ai-instagram"}
  // >
  //   {locale === "id"
  //     ? "IKUTI KAMI DI INSTRAGRAM"
  //     : "FOLLOW ON INSTAGRAM"}
  // </Button>
  // </div>
  //         <div className="col-md-8 wrapper-content">
  //           <div className="content-connect">
  //             {instaState.length > 0 ? instaState.map((item, i) => (
  //               <Link to={item.permalink} target="__blank">
  //                 <img
  //                   src={item.media_url}
  //                   className={`img-content ${anim(i + 1)}`}
  //                   alt={item.media_type}
  //                 />
  //                 {/* <ImageAnti
  //                   src={item.media_url}
  //                   ratio="r-1-1"
  //                   alt="content"
  //                   className={`img-content ${anim(i + 1)}`}
  //                 /> */}
  //               </Link>
  //             )) : null}
  //           </div>
  //           {/* </div> */}
  //         </div>
  //       </div>
  //     </div>
  //   </section>
  // )
}

export default HomeConnectUs
