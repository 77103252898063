import { Cover, Slider, Link, Image } from "components/anti"
import React, { useEffect, useRef, useState } from "react"
import logoBca from "@public/img/common/logo-bca.png"
import { useScrollAnim, useWindowSize } from "components/hooks/hooks"
import { dataHomeSlider } from "state/state"
import { SET_BACKGROUND_TYPE } from "lib/redux/types"
import { useDispatch } from "react-redux"

const SliderDescription = ({ item }) => {
  // const [trigger, anim, inView] = useScrollAnim("fadeIn", false)

  return (
    <></>
    // <div ref={trigger}>
    //   <div className={`${anim(1)}`}>
    //     <Image
    //       src={item.desktop}
    //       alt="img-label"
    //       objectFit="contain"
    //       width={80}
    //       height={44}
    //     // placeholder="blur"
    //     />
    //   </div>
    //   <h1 className={`cover-title ${anim(2)}`}>{item?.title}sdaa</h1>
    //   <h6 className={`${anim(3)}`}>{item?.text}</h6>
    //   <p className={`${anim(4)}`}>{item?.label}</p>
    //   <h6 className={`${anim(5)}`}>NAGAREY</h6>
    // </div>
  )
}

const HomeCover = ({ item, index, currentSlider, isMobile }) => {
  return (
    <>
      <Link to={item?.url} target="_blank">
        <div>
          <Image
            src={item?.desktop}
            alt={"desktop"}
            ratio="r-12-5"
            className="d-none d-md-block"
          />
          <Image
            src={{ src: item?.mobile }}
            alt={"mobile"}
            ratio="r-375-210"
            className="d-md-none"
          />
          {currentSlider == index && <SliderDescription item={item} />}
        </div>
        {/* <Cover
          img={{ src: item?.mobile }}
          imgMd={{ src: item?.desktop }}
          variant="fluid"
          imgHeight={"h-210px h-sm-400px h-md-600px"}
          className="banner-home"
        >
          {currentSlider == index && <SliderDescription item={item} />}
        </Cover> */}
      </Link>
    </>
  )
}

const HomeSlider = ({ hero }) => {
  const [currentSlider, setCurrentSlider] = useState(0)
  const sliderRef = useRef()

  const { width } = useWindowSize()
  const isMobile = width < 768

  const dispatch = useDispatch()

  const handleChangeBannerType = (next) => {
    sliderRef.current.slickGoTo(next)
    let nextBanner = hero?.banners[next]
    // if (nextBanner)
    //   dispatch({
    //     type: SET_BACKGROUND_TYPE,
    //     payload: nextBanner.background_type,
    //   })
  }

  const customDotComp = () => (
    <div className="container">
      <div className="custom_dot">
        {hero?.banners?.map((res, i) => (
          <div
            key={i}
            className={`dot mr-2 cursor-pointer ${
              currentSlider === i && "active-dot"
            }`}
            onClick={() => {
              handleChangeBannerType(i)
            }}
          ></div>
        ))}
      </div>
    </div>
  )

  return (
    <section className="sc-home-slider">
      <div className="banner-content">
        <Slider
          forwardRef={(slider) => (sliderRef.current = slider)}
          beforeChange={(cur, nx) => {
            setCurrentSlider(nx)
          }}
          infinite
          autoplay
          autoplaySpeed={5000}
          speed={1000}
          showInitial={1}
          showXxlDown={1}
          showXlDown={1}
          showLgDown={1}
          showMdDown={1}
          showSmDown={1}
          scrollInitial={1}
          arrowsInitial={false}
          arrowsXxlDown={false}
          arrowsXlDown={false}
          arrowsLgDown={false}
          arrowsMdDown={false}
          fade
          dotsInitial={false}
        >
          {hero?.banners?.map((item, i) => (
            <HomeCover
              item={item}
              index={i}
              key={i}
              currentSlider={currentSlider}
              isMobile={isMobile}
            />
          ))}
        </Slider>
        {customDotComp()}
      </div>
    </section>
  )
}

export default HomeSlider
