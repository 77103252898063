import React from "react"

import { useScrollAnim } from "components/hooks/hooks"
import CardProduct from "components/global/card-product/card-product"
import imgProduct from "@public/img/sample/product.jpg"
import { Slider } from "@components/anti"

const HomeRecent = ({ wishlists, locale }) => {
  const [trigger, anim] = useScrollAnim()

  const [state, setState] = React.useState({
    recent: null,
  })

  React.useEffect(() => {
    let data = window.localStorage.getItem("recent-view")
    if (data) {
      let products = JSON.parse(data).reverse()

      setState((prev) => ({ ...prev, recent: products }))
    }
  }, [])

  return (
    <>
      <section className="sc-home-recent" ref={trigger}>
        {state.recent ? (
          <div className={`container ${anim(1)}`}>
            <h2 className={`title-section`}>
              {locale === "id" ? "Baru Dilihat" : "Recently Viewed"}
            </h2>
            <div className="home-recent-product">
              {/* <div className="recent-list d-sm-none">
                {state.recent.slice(0, 12).map((item, i) => (
                  <div key={i} className={`recent-item ${anim(i + 1)}`}>
                    <CardProduct
                      {...item}
                      img={imgProduct}
                      imgAlt={`recent-product-${i}`}
                      wishlists={wishlists}
                    />
                  </div>
                ))}
              </div> */}
              <div className="">
                <Slider
                  showInitial={5}
                  showXxlDown={5}
                  showXlDown={4}
                  showLgDown={3}
                  showMdDown={2}
                  showSmDown={2}
                  dotsInitial={false}
                  arrowsInitial={true}
                  nextArrow={<CustomNextArrow />}
                  prevArrow={<CustomPrevArrow />}
                >
                  {state.recent.slice(0, 15).map((item, i) => (
                    <CardProduct
                      className={`${anim(1 + i)}`}
                      key={i}
                      img={imgProduct}
                      {...item}
                      imgAlt={`sale-product-${i}`}
                      noHeartIcon
                    />
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        ) : null}
      </section>
    </>
  )
}

const CustomPrevArrow = (props) => {
  const { className, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <div className="wrapper-arrow">
        <span className="air ai-chevron-left" />
      </div>
    </div>
  )
}

const CustomNextArrow = (props) => {
  const { className, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <div className="wrapper-arrow">
        <span className="air ai-chevron-right" />
      </div>
    </div>
  )
}

export default HomeRecent
