import React from "react"
import { useScrollAnim, useWindowSize } from "components/hooks/hooks"
import { Button, Image, Link } from "components/anti"

const HomeProjectGallery = ({ gallery, locale }) => {
  const [trigger, anim] = useScrollAnim()
  const { width } = useWindowSize()
  return (
    <section ref={trigger} className="sc-home-project-gallery">
      <div className="container">
        <div className="title-wrapper">
          <h2 className={`title-section ${anim(1)}`}>
            {locale === "id" ? "Galeri Proyek" : "Project Gallery"}
          </h2>
          <Button
            variant="link"
            className={`hover-underline inverse ${anim(2)}`}
            link="https://nagarey.wpcomstaging.com/"
            target="_blank"
          >
            {locale === "id" ? "Lihat Semua" : "See All"}
          </Button>
        </div>
        <div className="project-gallery-list">
          {gallery?.banners?.map((item, i) => (
            <div
              key={item.id}
              className={`project-gallery__item ${anim(1 + i)}`}
              onClick={() => {
                if (width < 768) {
                  window.open(item.url, "_ blank")
                  // router.push(item.url)
                }
              }}
            >
              <Image src={item.desktop} ratio="r-3-4" />
              <div className="img-caption">
                <h2 className="title-caption">{item.title}</h2>
                <Link
                  to={item.url}
                  target="_blank"
                  className="btn-view-projects"
                >
                  View {item.title} Projects
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default HomeProjectGallery
